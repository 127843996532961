<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="ios-bar py-lg-2 pr-lg-3">
			<v-btn icon rounded exact :to="{name: 'Home'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<v-row justify="center">
				<v-col cols="12" lg="6">
					<v-card class="pa-5 mt-5 text-center" color="secondary">
						<v-card-title class="mb-5 d-block text-h4">account closure</v-card-title>
						<v-card-text>closing your account will permanently destroy all your memories and associated attachments and will we delete all data we have on file for you. You will no longer be able to log into this account and should you decide to rejoin rewind in the future, you will have to start from scratch.</v-card-text>
						<v-card-text>if you are sure you want to close your account, please enter your password below.</v-card-text>
						<v-row justify="center" class="mt-5">
							<v-col cols="12" lg="6" xl="4">
								<v-text-field v-model="credentials.password" label="password" required :type="showPassword ? 'text' : 'password'" append-icon="mdi-eye" @click:append="showPassword = !showPassword" solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center" class="mt-5">
							<v-col cols="12">
								<v-alert color="accent" v-if="user.subscription_method === 'iap'">
									Please note that you have an active subscription via In App Purchase. Please ensure you cancel your subscription via your mobile device's app store.
								</v-alert>
							</v-col>
						</v-row>
						<v-row justify="center" class="mt-2">
							<v-col cols="auto">
								<v-btn @click="destroyAccount">permanently delete my account</v-btn>
							</v-col>
							<v-col cols="auto">
								<v-btn :to="{name: 'Account'}" color="primary">keep my account</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import RFreeTrialWarning from './components/RFreeTrialWarning'
import RLinkedAccounts from './components/RLinkedAccounts'
import RAccountFooter from './components/RFooter'
import RGrid from './components/RGrid'
import RAccountDeletionWarning from './components/RAccountDeletionWarning'
import {toast} from "@/utils/helpers";

export default {
	name: "CloseAccount",
	metaInfo: {
		title: 'Account',
	},
	computed: {
		user: sync('user/user')
	},
	data: () => ({
		credentials: {
			password: null,
		},
		showPassword: false,
	}),
	methods: {
		destroyAccount(){
			this.$api.users.destroy(this.credentials).then(() => {
				this.$router.push({name: 'Logout'})
				toast.add("Account closed", 'error')
			}).catch((error) => {
				toast.handleResponseErrors(error.response.data.error)
			})
		}
	}
}
</script>